import { isAndroid, isIOS } from "react-device-detect";
import { useEffect } from "react";
import android from '../images/android.png';
import apple from '../images/apple.png';

const GetApp = () => {
  useEffect(()=> {
    if (isAndroid === true){
      return window.location.replace('https://play.google.com/store/apps/details?id=com.holidaymatsuri.app')
    } else if (isIOS === true){
      return window.location.replace('https://apps.apple.com/us/app/holiday-matsuri/id1489401164?ls=1')
    }
  })
  const renderAndroid = () => {
    if(!isIOS){
      return(
        <div className="card gx-2" style={{margin: 10}}>
          <div className="card-body">
            <center>
              <img src={android} style={{padding: '10px', width: '150px'}} alt="Android"  />
              <a href="https://play.google.com/store/apps/details?id=com.holidaymatsuri.app" className="btn btn-primary">Click Here</a>
            </center>
            
          </div>
        </div>
      )
    }
  }
  const renderIOS = () =>{
    if(!isAndroid){
      return(
        <div className="card gx-2" style={{margin: 10}}>
          <div className="card-body">
            <center>
              <img src={apple} alt="Apple App Store" style={{padding: '10px', width: '150px'}} />
              <a href="https://apps.apple.com/us/app/holiday-matsuri/id1489401164?ls=1" className="btn btn-primary">Click Here</a>
            </center>
          </div>
        </div>
      )
    }
  }
  return(
    <>
      <h1 style={{margin: 'auto', textAlign: 'center'}}>Get the Official Holiday Matsuri App</h1>
      <div className="d-flex justify-content-center">
        {renderAndroid()}
        {renderIOS()}
      </div>
    </>
  );
};

export default GetApp;
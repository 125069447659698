import React from "react";
import axios from 'axios';

export default class Parking extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      parkingLots: []
    }
  }
  getParking(){
    axios.get('https://panels.holidaymatsuri.com/api/parking_lots.json').then(res => {
      this.setState({parkingLots: res.data.parking_lots})
    })
  }
  componentDidMount(){
    this.getParking();
    setInterval(() => this.getParking(), 10000)
  }
  render() {
    return (
      <div className="container">
        <div className="col-12">
          <h1>Parking Lot Status</h1>
        </div>
        <div className="row">
          {this.state.parkingLots.map(p => {
            return (
              <div key={p.location_name} className="col-3 py-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{p.location_name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{p.info}</h6>
                    {p.lot_full ? <h1><span className="badge text-bg-danger">Full</span></h1> : <h1><span className="badge text-bg-success">Open</span></h1>}
                    <a href={`https://maps.google.com/?daddr=${p.lat},+${p.lng}`} className="btn btn-lg btn-primary">Directions</a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
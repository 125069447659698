function Footer(){
  return(
    <footer className="footer fixed-bottom mt-auto py-3">
      <div className="container">
        <div className="row">
          <div className="col-6">
            © All rights reserved • Holiday Matsuri • HMCONVENTIONS, LLC • 2011-2023
          </div>
          <div className="col-6">
            
            <ul className="list-unstyled list-inline text-center">
              <li className="list-inline-item">
                <a href="http://facebook.com/holidaymatsuri" rel="noreferrer" target="_blank">
                  <span className="fa-stack fa-1x">
                    <i className="fas fa-circle fa-stack-2x fb-circle"></i>
                    <i className="fab fa-facebook-f fa-stack-1x"> </i>
                  </span>
                </a>
                
              </li>
              <li className="list-inline-item">
                <a href="https://x.com/holidaymatsuri" rel="noreferrer" target="_blank">
                  <span className="fa-stack fa-1x">
                    <i className="fas fa-circle fa-stack-2x tw-circle"></i>
                    <i className="fab fa-twitter fa-stack-1x"> </i>
                  </span>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://instagram.com/holidaymatsuri" rel="noreferrer" target="_blank">
                  <span className="fa-stack fa-1x">
                    <i className="fas fa-circle fa-stack-2x insta-circle"></i>
                    <i className="fab fa-instagram fa-stack-1x"> </i>
                  </span>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://twitch.tv/Holiday_Matsuri" rel="noreferrer" target="_blank">
                  <span className="fa-stack fa-1x">
                    <i className="fas fa-circle fa-stack-2x twitch-circle"></i>
                    <i className="fab fa-twitch fa-stack-1x"> </i>
                  </span>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://youtube.holidaymatsuri.com/" rel="noreferrer" target="_blank">
                  <span className="fa-stack fa-1x">
                    <i className="fas fa-circle fa-stack-2x yt-circle"></i>
                    <i className="fab fa-youtube fa-stack-1x"> </i>
                  </span>
                </a>
              </li>
            </ul>          
          </div>
        </div>  
      </div>
    </footer>
  )
}
export default Footer;

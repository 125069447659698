import { Outlet } from "react-router-dom";
import HMNavbar from '../components/Navbar';
import Footer from '../components/Footer'

const Layout = () => {
  return (
    <>
        <HMNavbar />
        <Outlet />
        <Footer />
    </>
    
  )
};

export default Layout;
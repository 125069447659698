import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GetApp from './pages/GetApp';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Parking from './pages/Parking';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />}/>
          <Route path='get-app' element={<GetApp />} />
          <Route path='parking' element={<Parking />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
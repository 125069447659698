import React from 'react';
import './modal.css';

export default class Modal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      event: null,
      show: false,
    }
  }
  showHideclassName() {
    return this.props.show ? "modal display-block" : "modal display-none";
  }
  componentDidMount() {
    this.setState({event: this.props.event});
  }
  render() {
    return(this.props.show ? 
      <div className={this.showHideclassName()}>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`${this.props.event.slug}-title`}>{this.props.event.name}</h5>
              <button type="button" className="close" onClick={this.props.handleClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <span className="modal-sub-title">Location: </span>{this.props.event.location} <br />
              <span className="modal-sub-title">Start Time: </span>{this.props.event.start_time} <br />
              <span className="modal-sub-title">End Time: </span>{this.props.event.end_time} <br />
              <br />
              {this.props.event.age_rating === '18+' ? <p style={{fontWeight: 'bold', color: 'red'}}>18+ WRISTBAND REQUIRED</p> : null}
              <p>
                {this.props.event.description}
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.props.handleClose}>Close</button>
            </div>
          </div>
        </div>
      </div> : <></>
    )
  }
}
import React from "react";
import axios from "axios";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment-timezone";
import $ from 'jquery';
import Modal from "../components/Modal";



export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      events: [],
      loading: true,
      show: false,
      modalEvent: null
    }
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal = (item) => {
    console.log("PRESSED")
    this.setState({ show: true, modalEvent: item });
  };

  hideModal = () => {
    this.setState({ show: false, modalEvent: null });
  };
  async getEvents(){
    await axios.get('https://panels.holidaymatsuri.com/api/conventions/holiday-matsuri-2022/website.json').then(res => {
      this.setState({events: res.data.events, loading: false})
    })
  }
  backOnMyBS(){
    //should add a loding while the events are organized 
    function SchedulePlan( element ) {
      this.element = element;
      this.timeline = $('.timeline');
      this.timelineItems = this.timeline.find('li');
      this.timelineNumber = this.timelineItems.length;
      this.timelineStart = getScheduleTimestamp(this.timelineItems.eq(0).text());
      this.timelineUnitDuration = getScheduleTimestamp(this.timelineItems.eq(1).text()) - getScheduleTimestamp(this.timelineItems.eq(0).text())
      this.eventsWrapper = this.element.find('.events');
      this.eventsGroup = this.eventsWrapper.find('.events-group');
      this.singleEvents = this.eventsGroup.find('.single-event');
      this.eventSlotHeight = this.eventsGroup.eq(0).children('.top-info').outerHeight();
  
      this.modal = this.element.find('.event-modal');
      this.modalHeader = this.modal.find('.header');
      this.modalHeaderBg = this.modal.find('.header-bg');
      this.modalBody = this.modal.find('.body'); 
      this.modalBodyBg = this.modal.find('.body-bg'); 
      this.modalMaxWidth = 800;
      this.modalMaxHeight = 480;
  
      this.animating = false;
  
      this.initSchedule();
    }
  
    SchedulePlan.prototype.initSchedule = function() {
      this.placeEvents();
    };
  
    SchedulePlan.prototype.scheduleReset = function() {
      var mq = this.mq();
      if( mq === 'desktop' && !this.element.hasClass('js-full') ) {
        //in this case you are on a desktop version (first load or resize from mobile)
        this.eventSlotHeight = this.eventsGroup.eq(0).children('.top-info').outerHeight();
        this.element.addClass('js-full');
        this.placeEvents();
        this.element.hasClass('modal-is-open') && this.checkEventModal();
      } else if(  mq === 'mobile' && this.element.hasClass('js-full') ) {
        //in this case you are on a mobile version (first load or resize from desktop)
        this.element.removeClass('js-full loading');
        this.eventsGroup.children('ul').add(this.singleEvents).removeAttr('style');
        this.eventsWrapper.children('.grid-line').remove();
        this.element.hasClass('modal-is-open') && this.checkEventModal();
      } else if( mq === 'desktop' && this.element.hasClass('modal-is-open')){
        //on a mobile version with modal open - need to resize/move modal window
        this.checkEventModal('desktop');
        this.element.removeClass('loading');
      } else {
        this.element.removeClass('loading');
      }
    };
  
    
  
    SchedulePlan.prototype.placeEvents = function() {
      var self = this;
      this.singleEvents.each(function(){
        var newDay
        var start
        var duration
        //place each event in the grid -> need to set top position and height
        // start = getScheduleTimestamp($(this).attr('data-start'));
        if(getScheduleTimestamp($(this).attr('data-start')) < self.timelineStart && getScheduleTimestamp($(this).attr('data-start')) < 300){
          newDay = getScheduleTimestamp("24:00");
          start = getScheduleTimestamp($(this).attr('data-start')) + newDay;
          
        } else {
          start = getScheduleTimestamp($(this).attr('data-start'));
        }
        
        if(getScheduleTimestamp($(this).attr('data-end')) > self.timelineStart){
          duration = getScheduleTimestamp($(this).attr('data-end')) - start;
        } else {
          newDay = getScheduleTimestamp("24:00");
          duration = getScheduleTimestamp($(this).attr('data-end')) + newDay - start ;
        }
        var eventTop = 150*(start - self.timelineStart)/self.timelineUnitDuration
        // if(self.timelineStart > 570){
        //   eventTop = 150*(start - 570)/self.timelineUnitDuration
        //   console.log("Event: " + $(this).attr('data-event') + " timelineStart: " + self.timelineStart + " Duration: " + self.timelineUnitDuration + " Start: " + start);
        // }else{
        //   eventTop = 150*(start - self.timelineStart)/self.timelineUnitDuration
        // }
        // var eventTop = 150*(start - self.timelineStart)/self.timelineUnitDuration
        console.log("Event: " + $(this).attr('data-event') + " timelineStart: " + self.timelineStart + " Start: " + getScheduleTimestamp($(this).attr('data-start')) + " Duration: " + self.timelineUnitDuration);
        var eventHeight = 150*duration/self.timelineUnitDuration;
        // if(eventTop === 0){
        //   eventTop = 150
        // }
        
        $(this).css({
          top: (Math.abs(eventTop) -1) +'px',
          height: (eventHeight)+'px'
        });
      });
  
      this.element.removeClass('loading');
    };
    var schedules = $('.cd-schedule');
    var objSchedulesPlan = [],
      windowResize = false;
    
    if( schedules.length > 0 ) {
      schedules.each(function(){
        //create SchedulePlan objects
        objSchedulesPlan.push(new SchedulePlan($(this)));
      });
    }
  
    $(window).on('resize', function(){
      if( !windowResize ) {
        windowResize = true;
        (!window.requestAnimationFrame) ? setTimeout(checkResize) : window.requestAnimationFrame(checkResize);
      }
    });
  
    $(window).keyup(function(event) {
      if (event.keyCode === 27) {
        objSchedulesPlan.forEach(function(element){
          element.closeModal(element.eventsGroup.find('.selected-event'));
        });
      }
    });
  
    function checkResize(){
      objSchedulesPlan.forEach(function(element){
        element.scheduleReset();
      });
      windowResize = false;
    }
  
    function getScheduleTimestamp(time) {
      //accepts hh:mm format - convert hh:mm to timestamp
      time = time.replace(/ /g,'');
      var timeArray = time.split(':');
      var timeStamp = parseInt(timeArray[0])*60 + parseInt(timeArray[1]);
      return timeStamp;
    }
  }
  stickTop() {
    $(".overflow-panels").each(function() {
			var p = $(this);
			var times = p.find('.timeline');
			var schedule = p.find('.schedule-scroll');
			var headers = p.find('.header-scroll');
			
			schedule.scroll(function() {
				times.scrollTop(schedule.scrollTop());
				headers.scrollLeft(schedule.scrollLeft());
			});
		 });
  }
  componentDidMount() {
    this.getEvents();
    
    setTimeout(() => this.stickTop(), 2000)
  }
  componentDidUpdate(){
    this.backOnMyBS();
  }
  render() {
    return(
      this.state.loading ? <h1>Loading...</h1> :
      <div className="content-container">
        <Tab.Container
          defaultActiveKey="Wednesday"
          id="events-tabs"
          className="hm-tab"
        >
          <Nav variant="tabs" as="ul">
          {this.state.events.map(key => {
            return(
                <Nav.Item key={`${key.date}-tab-nav`} as="li">
                  <Nav.Link eventKey={key.date}>{key.date}</Nav.Link>
                </Nav.Item>
            )
          })}
          </Nav>
          <Tab.Content>
          {this.state.events.map((key, value) => {
            if(key.date === "Wednesday"){
              return(
                <Tab.Pane key={`${key.date}-tab`} className="overflow-panels" style={{overflowY: 'hidden'}} eventKey={key.date} title={key.date}>
                  <div className="header-scroll">
                    <div className="header">
                      {key.events.sort((a,b)=> (a.order > b.order ? 1 : -1)).map(key => {
                        return <div key={`${key.location}-header`} className="header-label" style={{width: '160px',}}>{key.location}</div>
                      })}
                    </div>
                  </div>
                  <div className="panels-section" style={{height: window.innerHeight - 250}}>
                    <div className="timeline">
                      <ul>               
                        <li><span>10:00 AM</span></li>
                        <li><span>10:30 AM</span></li>
                        <li><span>11:00 AM</span></li>
                        <li><span>11:30 AM</span></li>
                        <li><span>12:00 PM</span></li>
                        <li><span>12:30 PM</span></li>
                        <li><span>01:00 PM</span></li>
                        <li><span>01:30 PM</span></li>
                        <li><span>02:00 PM</span></li>
                        <li><span>02:30 PM</span></li>
                        <li><span>03:00 PM</span></li>
                        <li><span>03:30 PM</span></li>
                        <li><span>04:00 PM</span></li>
                        <li><span>04:30 PM</span></li>
                        <li><span>05:00 PM</span></li>
                        <li><span>05:30 PM</span></li>
                        <li><span>06:00 PM</span></li>
                        <li><span>06:30 PM</span></li>
                        <li><span>07:00 PM</span></li>
                        <li><span>07:30 PM</span></li>
                        <li><span>08:00 PM</span></li>
                        <li><span>08:30 PM</span></li>
                        <li><span>09:00 PM</span></li>
                        <li><span>09:30 PM</span></li>
                        <li><span>10:00 PM</span></li>
                        <li><span>10:30 PM</span></li>
                        <li><span>11:00 PM</span></li>
                        <li><span>11:30 PM</span></li>
                        <li><span>12:00 AM</span></li>
                        <li><span>12:30 AM</span></li>
                        <li><span>01:00 AM</span></li>
                        <li><span>01:30 AM</span></li>
                        <li><span>02:00 AM</span></li>
                        <li><span>02:30 AM</span></li>
                        <li><span>03:00 AM</span></li>
                        <li><span>03:30 AM</span></li>

                      </ul>
                    </div>
                    <div className="schedule-scroll" style={{display: 'inline-block', height: '100%'}}>
                      <div className="cd-schedule">
                        <div className="events">
                          <ul>
                            {key.events.sort((a,b)=> (a.order > b.order ? 1 : -1)).map(key => {
                              return (
                                <li key={`conainter-${key.location}`} className="events-group flex-nowrap" style={{width: '160px'}}>
                                  <ul>
                                    {key.events.map(item => 
                                        <li key={`event-${item.slug}`} className={`single-event ${item.age_rating === '18+' ? 'age-restricted': ''}`} onClick={() => this.showModal(item)} data-toggle="modal" data-target={`#${item.slug}`} data-start={moment(item.full_start).tz("America/New_York").format('HH:mm')} data-end={moment(item.full_end).tz("America/New_York").format('HH:mm')} data-content={item.slug} data-event={item.slug}>
                                          <div style={{whiteSpace: 'initial'}}>
                                            <h6>{item.name}</h6>
                                            <span className="event-time">{item.start_time} to {item.end_time}</span>
                                            <span className="event-host">Hosted by: {item.hosts}</span>
                                            <div className="event-rating">{item.age_rating === "G Rated" ? "G":item.age_rating}</div><br/>
                                            <div className="event-location">{item.location}</div>
                                          </div>
                                        </li>
                                      
                                    )}
                                  </ul>
                                </li>
                              )
                            }, this)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              )
            }else{
              return(
                <Tab.Pane key={`${key.date}-tab`} className="overflow-panels" style={{overflowY: 'hidden'}} eventKey={key.date} title={key.date}>
                  <div className="header-scroll">
                    <div className="header">
                      {key.events.sort((a,b)=> (a.order > b.order ? 1 : -1)).map(key => {
                        return <div key={`${key.location}-header`} className="header-label" style={{width: '160px',}}>{key.location}</div>
                      })}
                    </div>
                  </div>
                  <div className="panels-section" style={{height: window.innerHeight - 250}}>
                    <div className="timeline">
                      <ul>                   
                        <li><span>10:00 AM</span></li>
                        <li><span>10:30 AM</span></li>
                        <li><span>11:00 AM</span></li>
                        <li><span>11:30 AM</span></li>
                        <li><span>12:00 PM</span></li>
                        <li><span>12:30 PM</span></li>
                        <li><span>01:00 PM</span></li>
                        <li><span>01:30 PM</span></li>
                        <li><span>02:00 PM</span></li>
                        <li><span>02:30 PM</span></li>
                        <li><span>03:00 PM</span></li>
                        <li><span>03:30 PM</span></li>
                        <li><span>04:00 PM</span></li>
                        <li><span>04:30 PM</span></li>
                        <li><span>05:00 PM</span></li>
                        <li><span>05:30 PM</span></li>
                        <li><span>06:00 PM</span></li>
                        <li><span>06:30 PM</span></li>
                        <li><span>07:00 PM</span></li>
                        <li><span>07:30 PM</span></li>
                        <li><span>08:00 PM</span></li>
                        <li><span>08:30 PM</span></li>
                        <li><span>09:00 PM</span></li>
                        <li><span>09:30 PM</span></li>
                        <li><span>10:00 PM</span></li>
                        <li><span>10:30 PM</span></li>
                        <li><span>11:00 PM</span></li>
                        <li><span>11:30 PM</span></li>
                        <li><span>12:00 AM</span></li>
                        <li><span>12:30 AM</span></li>
                        <li><span>01:00 AM</span></li>
                        <li><span>01:30 AM</span></li>
                        <li><span>02:00 AM</span></li>
                        <li><span>02:30 AM</span></li>
                      </ul>
                    </div>
                    <div className="schedule-scroll" style={{display: 'inline-block', height: '100%'}}>
                      <div className="cd-schedule">
                        <div className="events">
                          <ul>
                            {key.events.sort((a,b)=> (a.order > b.order ? 1 : -1)).map(key => {
                              return (
                                <li key={`conainter-${key.location}`} className="events-group flex-nowrap" style={{width: '160px'}}>
                                  <ul>
                                    {key.events.map(item => 
                                        <li key={`event-${item.slug}`} className={`single-event ${item.age_rating === '18+' ? 'age-restricted': ''}`} onClick={() => this.showModal(item)} data-toggle="modal" data-target={`#${item.slug}`} data-start={moment(item.full_start).tz("America/New_York").format('HH:mm')} data-end={moment(item.full_end).tz("America/New_York").format('HH:mm')} data-content={item.slug} data-event={item.slug}>
                                          <div style={{whiteSpace: 'initial'}}>
                                            <h6>{item.name}</h6>
                                            <span className="event-time">{item.start_time} to {item.end_time}</span>
                                            <span className="event-host">Hosted by: {item.hosts}</span>
                                            <div className="event-rating">{item.age_rating === "G Rated" ? "G":item.age_rating}</div><br/>
                                            <div className="event-location">{item.location}</div>
                                          </div>
                                        </li>
                                      
                                    )}
                                  </ul>
                                </li>
                              )
                            }, this)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              )
            }
            
          })}
          </Tab.Content>
          <Modal event={this.state.modalEvent} show={this.state.show} handleClose={this.hideModal}/>
        </Tab.Container>
      </div>
    )
  }
}